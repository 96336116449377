function convertToDecimal(amt: number) {
  return Math.ceil(amt) / 100;
}

export function usePricing(amt: number, currency_code = "") {
  // const cc = useRegionStore().activeRegion?.currency_code ?? currency_code;
  const cc = currency_code == "" ? useRegionStore().currencyCode : currency_code || 'eur'
  const _amt = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: cc,
  });
  return _amt.format(convertToDecimal(amt ? amt : 0));
}
